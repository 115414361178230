'use client';
import { useClientSideEffect } from 'hooks/useClientSideEffect';
import { useState } from 'react';
import { truckMapConfig } from 'truckMapConfig';

export const usePlaceDetailsVectorMap = () => {
  const [vectorMap, enableVectorMap] = useState<boolean>(false);

  useClientSideEffect(() => {
    !vectorMap && !truckMapConfig.isSearchBot && enableVectorMap(true);
  });

  return vectorMap;
};
