import {
  createPlaceDetail,
  IPlaceDetail,
  IPlaceMetaType,
  PlaceDetailStatus,
  sortDetailByOrder
} from '@truckmap/common';
import {
  usePlace,
  usePlaceCoordinates,
  usePlaceRevalidate,
  usePlaceSectionsState
} from 'hooks/usePlaceDetails';
import { produce } from 'immer';

import { usePlaceDetailsFormReset } from './usePlaceDetailsFormReset';
import { usePlaceDetailsFormSave } from './usePlaceDetailsFormSave';

export function usePlaceDetailsFormMutation(meta: IPlaceMetaType) {
  const { data, mutate } = usePlaceSectionsState();
  const place = usePlace();
  const { latitude, longitude } = usePlaceCoordinates(place);
  const savePlaceDetails = usePlaceDetailsFormSave(meta);
  const revalidate = usePlaceRevalidate();
  const resetForm = usePlaceDetailsFormReset(meta);

  const submitPlaceDetail = (value: string) => {
    savePlaceDetails(
      () =>
        createPlaceDetail({
          latitude,
          longitude,
          placeId: place.id,
          placeMetaTypeId: meta.id,
          values: value
        }),
      resetForm,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      () => submitPlaceDetail(value),
      revalidate
    );
    mutate(
      produce(data, (draft) => {
        const currentSection = draft.find((section) => section.section === meta.section);

        currentSection.empty = currentSection.empty.filter(
          (sectionMeta) => sectionMeta.id !== meta.id
        );

        currentSection.pending.push({
          status: PlaceDetailStatus.PENDING,
          placeMetaType: meta,
          placeDetailValues: [
            {
              id: meta.id,
              value
            }
          ]
        } as IPlaceDetail);
        currentSection.pending = currentSection.pending.sort(sortDetailByOrder);
      })
    );
  };

  return submitPlaceDetail;
}
