import { usePageContext } from 'components/Page/PageContext';

export const usePlaceContext = () => usePageContext()?.placeContext;
export const usePlace = () => usePlaceContext()?.place;
export const usePlaceDetails = () => usePlace()?.placeDetails;
export const usePlaceCreateModal = () => usePlaceContext()?.placeCreateModal;
export const usePlaceSectionsState = () => usePlaceContext()?.placeSections;
export const usePlaceSections = () => usePlaceSectionsState()?.data;
export const usePlaceNearbyPoints = () => usePlaceContext()?.placeNearbyPoints;
export const usePlaceDataType = () => usePlaceContext()?.placeDataType;
export const usePlaceSummary = () => usePlaceContext()?.placeSummary;
export const usePlaceVoteSummary = () => usePlaceContext()?.voteSummary;
export const usePlaceAnnotationsData = () => usePlaceContext()?.placeAnnotationsData;
