'use client';
import {
  IPlace,
  PlaceMetaTypeSectionListItem,
  PlaceMetaTypeSlug,
  buildLatLon
} from '@truckmap/common';
import { usePlace, usePlaceContext } from 'hooks/usePlaceDetails/usePlaceDetailsContext';
import { filterValidPlaceTypes, getSortedSelectMultipleData } from 'lib/place/placeDetailsUtils';
import { useCallback, useMemo } from 'react';

export const usePlaceDetailsShowReportProblemModal = () => {
  const {
    placeType: { objectKey }
  } = usePlace();

  return objectKey !== 'place_type:city';
};

export const usePlaceMetatypeExtraData = (slug: PlaceMetaTypeSlug) =>
  usePlaceContext()?.extraPlaceDetailsData[slug];

export const usePlaceCoordinates = (currentPlace?: IPlace) => {
  const place = currentPlace ?? usePlace();
  return useMemo(() => buildLatLon(place), [place]);
};

export const useValidPlaceTypes = () => {
  const { allPlaceTypes = null } = usePlaceContext();
  return useMemo(() => filterValidPlaceTypes(allPlaceTypes), [allPlaceTypes]);
};

export const useSortedSelectMultipleMetaTypes = (details: PlaceMetaTypeSectionListItem) => {
  const sortedData = useMemo(() => getSortedSelectMultipleData(details), [details]);

  const hasPendingOrPublishedStatus = useCallback(
    (metaObjectKey: string) =>
      !!sortedData.sortedPublishedDetails.find(
        (detail) => detail.placeMetaType.objectKey === metaObjectKey
      ) ||
      !!sortedData.sortedPendingDetails.find(
        (detail) => detail.placeMetaType.objectKey === metaObjectKey
      ),
    [sortedData]
  );

  return useMemo(
    () => ({
      ...sortedData,
      hasPendingOrPublishedStatus
    }),
    [sortedData, hasPendingOrPublishedStatus]
  );
};
