'use client';
import { usePathname } from 'next/navigation';
import { PlaceRoutes } from 'types/common';

export const usePlaceURLs = () => {
  const pathname = usePathname();
  const pathParts = pathname.split('/').filter(Boolean);

  return (
    pathParts.length
      ? [pathParts[1], pathParts[2] as PlaceRoutes.REVIEWS | PlaceRoutes.LOCAL_MAP]
      : []
  ) as [string, PlaceRoutes.REVIEWS | PlaceRoutes.LOCAL_MAP | undefined];
};
