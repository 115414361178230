'use client';
import { parsePlaceMetaTypeSections } from '@truckmap/common';
import {
  usePlace,
  usePlaceContext,
  usePlaceSectionsState
} from 'hooks/usePlaceDetails/usePlaceDetailsContext';
import { getPlaceDetailKey } from 'lib/swrCache';
import { useSWRConfig } from 'swr';
import { truckMapConfig } from 'truckMapConfig';

let placeRevalidationDebouncer: NodeJS.Timeout;
export const usePlaceRevalidate = () => {
  const place = usePlace();
  const { mutate: revalidate } = useSWRConfig();
  const { mutate } = usePlaceSectionsState();
  const { placeTypeConfig, allPlaceMetaTypes } = usePlaceContext();

  return async () => {
    placeRevalidationDebouncer && clearTimeout(placeRevalidationDebouncer);
    placeRevalidationDebouncer = setTimeout(() => {
      revalidate(getPlaceDetailKey(place.id)).then((updatedPlace) => {
        mutate(parsePlaceMetaTypeSections(updatedPlace, placeTypeConfig, allPlaceMetaTypes));
      });
    }, truckMapConfig.placeDetails.submitDebounce);
  };
};
