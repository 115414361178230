import {
  IPlaceDetail,
  IPlaceMetaType,
  IPlaceType,
  PlaceMetaTypeSectionListItem
} from '@truckmap/common';
import uniqBy from 'lodash/uniqBy';

export const getDetailText = (detail: IPlaceDetail) =>
  detail.placeMetaType?.nameDisplay ?? detail.placeMetaType?.name;

export const isSelectMultiple = (placeMetaType: IPlaceMetaType) => placeMetaType.allowMultiple;

export const filterValidPlaceTypes = (allPlaceTypes: IPlaceType[]) =>
  allPlaceTypes.filter((placeType) => placeType.canBecomePlace);

export const getSortedSelectMultipleData = (details: PlaceMetaTypeSectionListItem) => {
  const sortedPublishedDetails = details.published?.filter((detail) =>
    isSelectMultiple(detail.placeMetaType)
  );

  const sortedPendingDetails = details.pending?.filter((detail) =>
    isSelectMultiple(detail.placeMetaType)
  );

  const sortedEmptyPlaceMetaTypes = details.empty?.filter((detail) => isSelectMultiple(detail));

  const sortedPublishedPlaceMetaTypes = sortedPublishedDetails.map(
    (detail) => detail.placeMetaType
  );
  const sortedPendingPlaceMetaTypes = sortedPendingDetails.map((detail) => detail.placeMetaType);

  const uniqueMetaTypeObjectKeys = uniqBy(
    sortedPublishedPlaceMetaTypes.concat(sortedPendingPlaceMetaTypes, sortedEmptyPlaceMetaTypes),
    'objectKey'
  ).map((uniqueMetaType) => uniqueMetaType.objectKey);

  return {
    sortedPublishedDetails,
    sortedPendingDetails,
    sortedEmptyPlaceMetaTypes,
    uniqueMetaTypeObjectKeys,
    sortedPublishedPlaceMetaTypes,
    sortedPendingPlaceMetaTypes
  };
};
