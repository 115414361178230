import { IPlaceMetaType, sortMetaTypeByOrder } from '@truckmap/common';
import { usePlaceSectionsState } from 'hooks/usePlaceDetails';
import { produce } from 'immer';
import { useCallback } from 'react';

export function usePlaceDetailsFormReset(meta: IPlaceMetaType) {
  const { data, mutate } = usePlaceSectionsState();

  return useCallback(() => {
    mutate(
      produce(data, (draft) => {
        const currentSection = draft.find((section) => section.section === meta.section);

        currentSection.pending = currentSection.pending.filter(
          (sectionMeta) => sectionMeta.id !== meta.id
        );
        currentSection.empty = currentSection.empty.sort(sortMetaTypeByOrder);
      })
    );
  }, [mutate, data, meta]);
}
