import { createPlaceDetail, IPlaceMetaType } from '@truckmap/common';
import { globalAlert } from 'components/common/Alert/AlertGlobalHotToast';
import { useTranslation, useTranslationDataType } from 'hooks/useContentful/useTranslation';
import { usePlaceRevalidate } from 'hooks/usePlaceDetails';
import { useBreakpoints } from 'hooks/useUI/useBreakpoints';
import { useCallback } from 'react';

export function usePlaceDetailsFormSave(meta: IPlaceMetaType) {
  const { t } = useTranslation();
  const metaTranslations = useTranslationDataType('place_meta_type');
  const { currentBreakpoint, breakpoints } = useBreakpoints();

  return useCallback(
    (
      apiCall: () => ReturnType<typeof createPlaceDetail>,
      undoAction: () => void,
      retryAction: (value: string) => void,
      revalidate: ReturnType<typeof usePlaceRevalidate>
    ) => {
      setTimeout(async () => {
        try {
          await apiCall();

          revalidate();

          globalAlert({
            id: 'place_details_toast',
            intent: 'success',
            position: currentBreakpoint === breakpoints.SM ? 'top-center' : 'bottom-right',
            message: `${t('PLACE_DETAILS_ALERT_SUCCESS')} ${
              metaTranslations.get(meta.name) ?? meta.slug
            }`,
            description: t('PLACE_DETAILS_ALERT_SUCCESS_DESCRIPTION'),
            hasCloseButton: true
          });
        } catch (e) {
          globalAlert({
            id: 'place_details_toast',
            intent: 'danger',
            position: currentBreakpoint === breakpoints.SM ? 'top-center' : 'bottom-right',
            message: t('ALERT_ERROR_MESSAGE'),
            description: `${t('PLACE_DETAILS_ALERT_ERROR_DESCRIPTION')} ${
              metaTranslations.get(meta.name) ?? meta.slug
            }`,
            action: retryAction,
            actionLabel: t('RETRY'),
            showSecondaryCancel: true
          });
        }
      }, 6000);
    },
    [meta]
  );
}
