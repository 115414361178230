import { useMemo } from 'react';
import { usePlaceContext } from './';

export const usePlaceDetailsReview = () => {
  const { reviewTemplate = null, review = null } = usePlaceContext();
  const templates = useMemo(
    () =>
      reviewTemplate.filter(
        ({ primary, placeReviewItemTypes }) => primary && placeReviewItemTypes?.length
      ),
    [reviewTemplate]
  );

  return (
    reviewTemplate &&
    review && {
      review,
      templates
    }
  );
};
