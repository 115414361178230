'use client';
import {
  ApiPaginationResponse,
  IPlace,
  IPlaceReview,
  fetchPlaceReviewVoteSummary,
  fetchPlaceReviews
} from '@truckmap/common';
import { PlaceReviewVoteSummaryResponse } from 'components/Place/PlaceDetails';
import { getPlaceReviewVoteSummaryKey, getPlaceReviewsKey } from 'lib/swrCache';
import { usePathname } from 'next/navigation';
import useSWR from 'swr';
import { truckMapConfig } from 'truckMapConfig';

export const usePlaceReviewStore = (place: IPlace) => {
  const pathname = usePathname();

  const pathParts = pathname.split('/').filter(Boolean);
  const page = pathParts[2];
  const pageNumber = page ? parseInt(page, 10) : 1;

  return useSWR<ApiPaginationResponse<IPlaceReview[]>>(getPlaceReviewsKey(place.id), () =>
    fetchPlaceReviews({
      placeId: place.id,
      limit: truckMapConfig.placeDetails.reviewsPerPage,
      page: pageNumber
    })
  );
};

export const usePlaceReviewVoteSummaryStore = (place: IPlace) =>
  useSWR<PlaceReviewVoteSummaryResponse>(getPlaceReviewVoteSummaryKey(place.id), () =>
    fetchPlaceReviewVoteSummary(place.id)
  );
